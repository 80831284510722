import * as React from 'react'

const Page = () => {
    return (
        <>
            ebenso wie den Höchsten, keinen ausgenommen. Daher ergibt sich aus der Schrift, dass wir frei sind, und das
            wollen wir sein. Aber nicht, dass wir ganz und gar frei sein und keine Obrigkeit haben wollen, das lehrt uns
            Gott nicht. Wir sollen nach Geboten leben, nicht nach unserem freien fleischlichen Eigensinn, sondern Gott
            lieben, ihn als unseren Herrn in unserem Nächsten erkennen und alles tun, was auch wir gern hätten und was
            uns Gott in seinem Abendmahl zuletzt geboten hat. Darum sollen wir nach seinem Gebot leben. Aber zeigt und
            weist uns dies Gebot an, der Obrigkeit nicht gehorsam zu sein? Doch nicht allein der Obrigkeit, sondern
            jedermann gegenüber sollen wir demütig sein. So wollen wir auch gegenüber unserer gewählten und eingesetzten
            Obrigkeit, die uns von Gott eingesetzt ist, in allen rechtlich verpflichtenden und christlichen Sachen gern
            gehorsam sein. Wir haben auch keinen Zweifel, ihr werdet uns in der Eigenschaft als wahre und rechte
            Christen gern aus der Leibeigenschaft entlassen oder uns durch das Evangelium belehren, dass wir Eigenleute
            seien.<br/>
            <br/>
            <h4>Der vierte Artikel</h4>
            Zum Vierten: Es ist bisher der Brauch gewesen, dass kein armer Mann das Recht gehabt hat, Wild, Vögel oder
            Fische in fließendem Wasser zu fangen, was uns ganz schändlich und unbrüderlich dünkt, sondern eigennützig
            und dem Wort Gottes nicht gemäß. Auch hält die Obrigkeit das Wild an manchen Orten uns zum Hohn und zu
            großem Schaden. Während die unvernünftigen Tiere das Unsere, das Gott zum Nutzen des Menschen hat wachsen
            lassen, zu unserem Schaden leichtfertig wegfressen, müssen wir leiden und dazu still schweigen. Das ist
            wider Gott und den Nächsten. Denn als Gott den Menschen schuf, hat er ihm Gewalt gegeben über alle Tiere,
            über den Vogel in der Luft und über den Fisch im Wasser. Darum ist unser Begehren: Wenn einer Gewässer
            besitzt und mit einer ausreichenden Urkunde be-
        </>
    )
}

export default Page;
