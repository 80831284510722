import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "./facsimile";
import { StaticImage } from "gatsby-plugin-image";

const TextBlockMore = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Memmingen/memmingen_bild_01_06.jpg"}
                               data-glightbox="title:  Sebastian Lotzer; description: .custom-desc1_3; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_06.jpg" width={335} layout="fixed" placeholder="none" alt="Sebastian Lotzer"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_3">
                                <p> Ain hailsame Ermanunge an die ynwoner zu horw (Eine heilsame Ermahnung an die Einwohner zu Horb), gedruckt in Augsburg, 1523 </p>
                                <p>
                                    Die Forschung verweist darauf, dass sich die gemeindeorientierte Haltung und
                                    theologischen Aussagen der Zwölf Artikel auch in Flugschriften Lotzers finden. Mit
                                    ihnen trug er dazu bei, dass sich immer mehr Bürger und reichsstädtische Bauern in
                                    Memmingen zur evangelischen Lehre bekannten.
                                </p>
                                <p className="copy">Abb. Bayerische Staatsbibliothek München, Res/4 Hom. 1901,62,23</p>
                            </div>
                            <figcaption>
                                Sebastian Lotzer: Ain hailsame Ermanunge an die ynwoner zu horw (Eine heilsame Ermahnung an die Einwohner zu Horb), gedruckt in Augsburg, 1523
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Memmingen/memmingen_bild_01_07.jpg"}
                               data-glightbox="title: Siegel des Baltringer Haufens; description: .custom-desc1_4; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_07.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Siegel des Baltringer Haufens"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_4">
                                <p>
                                    Ulrich Schmid, der Führer des Baltringer Haufens, warb Sebastian Lotzer als Schreiber an.
                                    Das Siegel zeigt eine Pflugschar sowie die Initialen DWGBIE: „Das Wort Gottes Bleibt In Ewigkeit“.
                                </p>
                                <p className="copy">Abb. Stadtarchiv Memmingen, A Bd 299, Foto: Franz Liesch, Erinnerungsstätte
                                    Baltringer Haufen – Bauernkrieg in Oberschwaben</p>
                            </div>
                            <figcaption>
                                Siegel des Baltringer Haufens auf einem Schreiben von Ulrich Schmid vom 12. April 1525
                                <span className="description">
                                Ulrich Schmid, der Führer des Baltringer Haufens, warb Sebastian Lotzer als Schreiber an.
                                Das Siegel zeigt eine Pflugschar sowie die Initialen DWGBIE: „Das Wort Gottes Bleibt In Ewigkeit“.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="bg-img parallax-bg parallax-memmingen" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <Container.Facsimile
                                pages={Pages['01_10']}
                                img="/images/Memmingen/memmingen_bild_01_10.jpg"
                                alttext="Antwort des Rates auf die Eingabe der Memminger Bauern (10 Artikel) vom 15. März 1525 ">
                            </Container.Facsimile>


                            <div className="glightbox-desc custom-desc1_5">
                                <p>
                                    Schon bevor die Zwölf Artikel abgefasst wurden, traten die Memminger Bauern mit
                                    Forderungen an den Rat der Stadt heran. Dieser reagierte erstaunlich entgegenkommend
                                    und machte in einigen Punkte Zugeständnisse. Der Rat hatte in der Stadt eine wichtige
                                    politische Rolle. Memmingen gehörte zu den „zunftverfassten“ Städten, wo die Zünfte
                                    in einem komplizierten Verfahren die politischen Organe (Rat, Ammann, Bürgermeister)
                                    wählten. Eberhard Zangmeister, der Zunftmeister der Kramer, zählte im Rat zu den
                                    Befürwortern der Reformation und spielte auch eine wichtige Rolle für das Zusammenkommen
                                    der Bauern in Memmingen.
                                </p>
                                <p className="copy">Abb. Stadtarchiv Memmingen, A 341/6</p>
                            </div>
                            <figcaption>
                                Antwort des Rates auf die Eingabe der Memminger Bauern (10 Artikel) vom 15. März 1525
                                <span className="description">
                                    Schon bevor die Zwölf Artikel abgefasst wurden, traten die Memminger Bauern mit
                                    Forderungen an den Rat der Stadt heran. Dieser reagierte erstaunlich entgegenkommend
                                    und machte in einigen Punkte Zugeständnisse. Der Rat hatte in der Stadt eine wichtige
                                    politische Rolle. Memmingen gehörte zu den „zunftverfassten“ Städten, wo die Zünfte
                                    in einem komplizierten Verfahren die politischen Organe (Rat, Ammann, Bürgermeister)
                                    wählten. Eberhard Zangmeister, der Zunftmeister der Kramer, zählte im Rat zu den
                                    Befürwortern der Reformation und spielte auch eine wichtige Rolle für das Zusammenkommen
                                    der Bauern in Memmingen.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Memmingen/memmingen_bild_01_08.jpg"}
                               data-glightbox="title: St. Martin in Memmingen; description: .custom-desc1_6; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_08.jpg" width={335} layout="fixed" placeholder="none" alt="St. Martin in Memmingen"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_6">
                                <p>
                                    Die in ihrer heutigen Form um 1325 begonnene und um 1500 vollendete dreischiffige
                                    spätgotische Stadtkirche liegt am nordwestlichen Rand der Memminger Altstadt.
                                    Hier predigte Christoph Schappeler.
                                </p>
                                <p className="copy">Abb. Ev.-Luth. Kirchengemeinde Memmingen-St. Martin</p>
                            </div>
                            <figcaption>
                                St. Martin in Memmingen
                                <span className="description">
                                    Die in ihrer heutigen Form um 1325 begonnene und um 1500 vollendete dreischiffige
                                    spätgotische Stadtkirche liegt am nordwestlichen Rand der Memminger Altstadt.
                                    Hier predigte Christoph Schappeler.
                            </span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox karte">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Memmingen/konfessionelle-Gliederung-1580.png"}
                               data-glightbox="title: Die konfessionelle Gliederung des deutschen Südwestens um 1580; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Memmingen/konfessionelle-Gliederung-1580.png" width={335} layout="fixed" placeholder="none"
                                     alt="Die konfessionelle Gliederung des deutschen Südwestens um 1580"/>
                            </a>
                            <figcaption>
                                Die konfessionelle Gliederung des deutschen Südwestens um 1580
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlockMore
