import * as React from 'react'

const Page = () => {
    return (
        <>
            <h4>Der siebente Artikel</h4>
            Zum Siebenten: Wir wollen uns hinfort von einer Herrschaft nicht weiter beschweren lassen. Sondern: Wie eine
            Herrschaft einem ein Gut verliehen hat, so soll man's besitzen, laut der Vereinbarung zwischen dem Herrn und
            dem Bauern. Der Herr soll einen nicht weiter zwingen und bedrängen, mehr Dienste und noch anderes umsonst zu
            verlangen, damit der Bauer ein solches Gut unbeschwert, also ruhig, bewirtschaften und nutzen kann. Wenn
            aber dem Herrn Dienste von Nöten wären, soll der Bauer ihm gegenüber willig und gehorsam sein, doch zu einer
            Stunde und einer Zeit, wo es dem Bauern nicht zum Nachteil gereicht. Und er soll ihm nur um einen
            angemessenen Lohn Dienst tun.<br/>
            <br/>
            <h4>Der achte Artikel</h4>
            Zum Achten sind wir dadurch beschwert, dass die Güter – und es sind derer viele, die Güter besitzen – den
            Pachtzins nicht mehr erbringen und die Bauern dadurch das Ihre einbüßen und zu Grunde gehen. Die Herrschaft
            soll diese Güter von ehrenhaften Leuten begutachten lassen und nach Recht und Angemessenheit einen Pachtzins
            festsetzen, damit der Bauer seine Arbeit nicht umsonst tut. Denn jeder Arbeiter ist seines Lohnes wert.<br/>
            <br/>
            <h4>Der neunte Artikel</h4>
            Zum Neunten sind wir beschwert durch die großen Frevel, für die man stets neue Strafbestimmungen festsetzt.
            Nicht, dass man uns nach den Umständen der Sache straft: sondern zu Zeiten geschieht das aus großer
            Missgunst und zu Zeiten nach großem Wohlwollen. Es ist daher unsere Meinung, dass man uns nach altem
            geschriebenen Strafmaß richtet, je nachdem, worum es sich bei der Tat handelt, und nicht nach Willkür.
        </>
    )
}

export default Page;
