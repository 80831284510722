import * as React from 'react'

const Page = () => {
    return (
        <>
            <h4>Der zehnte Artikel</h4>
            Zum Zehnten sind wir dadurch beschwert, dass manche sich Wiesen angeeignet haben, desgleichen Äcker, die
            aber einer Gemeinde gehören. Diese werden wir wieder in Gemeindehand zurücknehmen, es sei denn, dass man sie
            redlich gekauft hat. Wenn man sie aber unrechtmäßiger Weise erworben hat, soll man sich gütlich und
            brüderlich miteinander vergleichen nach Art der Streitsache.<br/>
            <br/>
            <h4>Der elfte Artikel</h4>
            Zum Elften: Wir wollen den Brauch, genannt Todfall, ganz und gar abgeschafft haben. Denn wir wollen nicht
            mehr dulden noch gestatten, dass man Witwen und Waisen das Ihre so schändlich nehmen und sie dessen berauben
            darf. Das ist gegen Gott und jede Ehre. Es ist aber an vielen Orten in mancherlei Art und Weise geschehen,
            und zwar von denen, die sie beschützen und beschirmen sollten. Sie haben uns geschunden und ausgebeutet. Und
            hätten sie nur Fug und Recht gehabt, so hätten sie uns alles ganz genommen. Das will Gott nicht mehr dulden.
            Sondern dieser Brauch soll ganz wegfallen. Kein Mensch soll in Zukunft verpflichtet sein im Todfall etwas zu
            geben, weder wenig noch viel.<br/>
            <br/>
            <h4>Beschluss</h4>
            Zwölftens ist unsere Schlussfolgerung und unsere endgültige Meinung: Wenn einer oder mehrere Artikel hier
            aufgestellt sein sollten, die dem Wort Gottes nicht gemäß wären, was wir aber von diesen Artikeln nicht
            glauben, so wollen wir von ihnen Abstand nehmen, wenn man uns das mit der Schrift begründet. Falls man uns
            schon jetzt einige Artikel zugestanden hätte und sich hernach herausstellen würde, dass sie Unrecht wären,
            so sollen sie von Stund' an tot und hinfällig sein
        </>
    )
}

export default Page;
