import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background={ortName} key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                1525 <br/>
                                Memmingen <br/>
                                Haus der Kramerzunft
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Freiheitsrechte</li>
                                <li>Mitbestimmung</li>
                            </ul>
                            <p> In den Zwölf Artikeln forderten die Bauern in Oberschwaben 1525 Freiheits- und Mitbestimmungsrechte.</p>
                            <p> Sie begründeten ihre Forderungen mit dem Evangelium und beriefen sich auf das „göttliche Recht“. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Memmingen/memmingen_bild_01_03.jpg"}
                                   data-glightbox="title: Haus der Kramerzunft; description: .custom-desc1_1; descPosition: bottom;">
                                   <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_03.jpg" width={335} layout="fixed" alt="Das Haus der Kammerzunft"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Eine Zunft war der Zusammenschluss einer Berufsgruppe, die sich im Zunfthaus traf.
                                        Die Memminger Kramer (Händler) waren ab 1479 im Besitz des Hauses am Stadtbach.
                                        1525 wurde es den Bauern als Versammlungsort zur Verfügung gestellt.
                                    </p>
                                    <p className="copy">Abb. Stadtarchiv Memmingen</p>
                                </div>
                                <figcaption>
                                    Haus der Kramerzunft
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Memmingen/memmingen_bild_01_02.jpg"}
                                   data-glightbox="title: Bundesordnung der oberschwäbischen Bauern, Augsburg 1525; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_02.jpg"  width={335} layout="fixed" alt="Foto der Bundesordnung der oberschwäbischen Bauern, Augsburg 1525"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        In der Bundesordnung sind Details zur Organisation und Zusammenarbeit der
                                        Bauernhaufen festgehalten. In ihr zeigen sich die Verfassungsvorstellungen der
                                        Bauern in der „Christlichen Vereinigung“.
                                    </p>
                                    <p className="copy">Abb. Bayerische Staatsbibliothek München, Res/4 Eur. 332,33</p>
                                </div>
                                <figcaption>
                                    Bundesordnung der oberschwäbischen Bauern, Augsburg 1525
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
