import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Hiernach folgen nun die Artikel.</h3>
            <h4>Der erste Artikel</h4>
            Zum Ersten: Es ist unsere demütige Bitt' und unser Begehr', auch unser aller Wille und unsere Meinung, dass
            wir fortan Gewalt und Macht haben wollen, damit eine ganze Gemeinde einen Pfarrer selbst aussuchen und
            wählen kann. Auch soll sie Vollmacht haben, denselben wieder abzusetzen, wenn er sich nicht dem Evangelium
            gemäß verhält. Der so gewählte Pfarrer soll uns das heilige Evangelium klar und ohne allen menschlichen
            Zusatz, auch ohne menschliche Lehre und menschliches Gebot predigen. Denn wenn uns der wahre Glaube
            beständig verkündet wird, ist das für uns ein Grund, Gott um seine Gnade zu bitten, diesen wahren Glauben in
            uns aufzurichten und zu stärken. Denn wenn seine Gnade in uns nicht wirksam wird, bleiben wir stets Fleisch
            und Blut, was dann nicht von Nutzen ist. So steht ja auch klar in der Schrift, dass wir nur durch den wahren
            Glauben zu Gott kommen können und durch seine Barmherzigkeit selig werden. Darum ist uns ein Wegführer und
            Pfarrer von Nöten. So wird es auch in der Schrift gesagt und begründet.<br/>
            <br/>
            <h4>Der zweite Artikel</h4>
            Zum Zweiten: Da der Kornzehnt im Alten Testament festgesetzt und im Neuen bestätigt wurde, wollen wir den
            rechtmäßigen Kornzehnt gerne geben, doch wie es rechtlich und christlich vertretbar ist. Denn man soll ihn
            Gott geben und mit den Seinen teilen. Auch steht er einem Pfarrer zu, wenn er das Wort Gottes klar
            verkündet. Es ist unser Wille, dass hinfort unsere Kirchenpröbste, die eine Gemeinde einsetzt, diesen
            Zehnten
        </>
    )
}

export default Page;
