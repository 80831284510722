import * as React from 'react'

const Page = () => {
    return (
        <>
            und nicht mehr gelten. Desgleichen gilt: Wenn sich auf Grund der Wahrheit der Schrift noch mehr Artikel
            finden sollten, die sich gegen Gott richten und eine Beschwernis für den Nächsten wären, so wollen wir sie
            uns vorbehalten und schon beschlossen haben. Wir wollen uns in der christlichen Lehre üben und nach ihr
            leben. Darum bitten wir Gott den Herrn, der uns hierzu verhelfen kann und sonst niemand. Der Friede Christi
            sei mit uns allen.
        </>
    )
}

export default Page;
