import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3> Übertragung der „Zwölf Artikel“ ins Neuhochdeutsche von Heide Ruszat-Ewig </h3>

            DIE GRÜNDLICHEN UND RECHTEN HAUPTARTIKEL ALLER BAUERNSCHAFT UND HINTERSASSEN DER GEISTLICHEN UND
            WELTLICHEN OBERKEITEN, VON WELCHEN SIE SICH BESCHWERT VERMEINEN
        </>
    )
}

export default Page;
