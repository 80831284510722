import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "./facsimile";

const TextBlock1Blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„…das wir frey seien <br/> <span>&nbsp;</span> und wollen sein“</h2>
                    <div className="inner-container">
                        <p> Im Haus der Kramerzunft in Memmingen wurde vor rund 500 Jahren Freiheits- und
                            Verfassungsgeschichte geschrieben. Während des Bauernkriegs (1524–1526) kämpften die Bauern
                            gegen Missstände der bestehenden Herrschaftsform und für mehr Rechte. Rund 50 Vertreter der
                            Bauern aus Oberschwaben kamen 1525 in Memmingen zusammen.
                        </p>
                        <p>
                            Die Bauern verabschiedeten schriftlich ihre Forderungen – die Zwölf Artikel. Unterstützt
                            wurden sie auch von Bürgern und Anhängern der Reformation. Die Zwölf Artikel begründen
                            soziale und politische Ziele mit dem Evangelium. Es ging unter anderem um freie Pfarrerwahl,
                            Aufhebung der Leibeigenschaft, Erleichterungen bei den Abgaben und eine Erweiterung der
                            Gemeinderechte.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">
                            <p>
                                Die Flugschrift mit den Zwölf Artikeln verbreitete sich rasch: In nur zwei Monaten
                                erschienen davon im ganzen Reich 28 Drucke, über 25.000 Exemplare waren im Umlauf.
                                Im Aufstandsgebiet des Bauernkriegs – weit über Oberschwaben hinaus – hatten sie
                                Bedeutung
                                für den Kampf der Bauern.
                            </p>
                            <p>
                                Bei den Treffen in Memmingen diskutierten die Bauern auch ihr Vorgehen: Sollte man
                                verhandeln oder kämpfen? Sie gründeten eine Vereinigung und schrieben Grundsätze in
                                einer „Bundesordnung“ fest. Nun sollten Verhandlungen mit den Herrschaften geführt
                                werden.
                            </p>
                            <p>
                                Die Obrigkeiten gingen nicht auf das Verhandlungsangebot ein, vielmehr schlug der
                                Schwäbische Bund die Bauern militärisch nieder. Dennoch konnten die Bauern zum Teil ihre
                                Rechtsstellung verbessern. Die Zwölf Artikel gehören zu den frühen niedergeschriebenen
                                Forderungen nach Freiheits- und Mitbestimmungsrechten in Europa.
                            </p>
                            <br/>
                        </Container.More>

                        <div className="center">
                            <figure className="lightbox">
                                <Container.Facsimile
                                    pages={Pages['01_12']}
                                    img="/images/Memmingen/memmingen_bild_01_01.jpg"
                                    alttext="Titelseite der 12 Artikel, 1525. Klick öffnet die PDF">
                                    <figcaption>Titelseite der 12 Artikel, 1525
                                        <span className="description">
                                        Bei dieser Ausgabe der Zwölf Artikel handelt es sich um einen Druck aus der
                                        Zwickauer Druckerei des Augsburgers Johann Schönsperger dem Jüngeren.
                                    </span>
                                        <span
                                            className="copy">Abb. Staats- und Stadtbibliothek Augsburg, 4 Th H 1353<br/>
                                        Blätterseiten: Stadtarchiv Memmingen</span>
                                    </figcaption>
                                </Container.Facsimile>
                            </figure>
                        </div>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1Blue
