import * as React from 'react'

const Page = () => {
    return (
        <>
            einsammeln und einnehmen. Davon soll einem Pfarrer, der von einer ganzen Gemeinde gewählt worden ist, sein
            angemessener und ausreichender Unterhalt gegeben werden, ihm und den Seinen, je nach Einschätzung einer
            ganzen Gemeinde. Und was übrig bleibt, soll man mit armen Bedürftigen, falls sie im selben Dorf vorhanden
            sind, teilen, je nach der Sachlage und der Entscheidungen der Gemeinde. Was dann noch übrig bleibt, soll man
            behalten, falls man bei einer Landesbedrohung Kriegsdienst leisten muss. Um die Armen nicht mit einer
            Landessteuer zu belasten, soll man's von diesem Überschuss bezahlen. Auch für den Fall, dass es ein Dorf
            oder mehrere gäbe, die aus irgendeiner Not heraus, den Zehnten selbst verkauft hätten, so soll derjenige,
            der beweisen kann, ihn von einem Dorf gekauft zu haben, dadurch nicht Schaden erleiden. Sondern: Wir wollen
            in angemessener Weise und nach Lage der Dinge einen Ausgleich mit ihm suchen und ihm den Zehnten in einer
            zumutbaren Zeitspanne wieder ablösen. Aber wer von keinem Dorf solchen Zehnten gekauft hat, sondern wessen
            Vorfahren ihn sich selbst angeeignet haben, dem sind wir nichts weiter schuldig und wollen ihm auch nichts
            geben. Geben wollen wir ihn nur – wie's oben steht – unserem gewählten Pfarrer zu seinem Lebensunterhalt,
            sodann um nachträglich etwas abzulösen oder um es an Bedürftige zu verteilen, wie es in der Heiligen Schrift
            steht. Den kleinen Zehnt wollen wir gar nicht mehr geben, sei es für geistliche oder weltliche Herrschaften.
            Denn Gott der Herr hat das Vieh frei für den Menschen geschaffen. Diesen Zehnten halten wir für
            unrechtmäßig, ihn haben die Menschen erfunden. Darum wollen wir ihn nicht weiter geben.<br/>
            <br/>
            <h4>Der dritte Artikel</h4>
            Zum Dritten: Es ist bisher der Brauch gewesen, dass uns die Herren für ihre Leibeigene gehalten haben. Das
            ist zum Erbarmen, angesichts dessen, dass uns Christus alle mit seinem kostbaren Blutvergießen erlöst und
            erkauft hat, den Hirten
        </>
    )
}

export default Page;
