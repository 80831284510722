import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock6 = (key) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Christoph Schappeler – ein einflussreicher Theologe</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <p>
                        Der 1472 in St. Gallen geborene Christoph Schappeler trat als studierter Rechtswissenschaftler
                        und Theologe 1513 eine Predigerstelle in der Memminger St.-Martins-Kirche an. Er orientierte sich
                        an der Lehre von Ulrich Zwingli, der theologische Grundpositionen Luthers teilte, aber die Rolle
                        der Obrigkeit kritischer sah und die Idee einer gemeindeorientierten Gesellschaft vertrat.
                        Memmingen wechselte als eine der ersten Reichsstädte Süddeutschlands ins Lager der Reformation.
                    </p>
                    <p>
                        Die aufständischen Bauern unterstützte Schappeler vor allem bei ihrer Forderung nach der Abschaffung
                        der Leibeigenschaft. Gemeinsam mit Lotzer war er an der Abfassung der Memminger Bundesordnung
                        und der Zwölf Artikel beteiligt. Er schloss sich aber nicht dem Aufstand an, sondern versuchte,
                        die Bauern von einem gewaltfreien Weg zu überzeugen. Als im Juni 1525, zum Ende des Bauernkriegs,
                        der Schwäbische Bund in Memmingen einrückte, musste er nach St. Gallen fliehen. Bis zu seinem
                        Tod 1551 war er an verschiedenen Kirchen als Prediger tätig.
                    </p>
                    <p/>
                    <figure className="center">
                        <StaticImage src="../../../../static/images/Memmingen/schappeler.jpg" width={335} layout="fixed" placeholder="none" alt=""/>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Memmingen/memmingen_bild_01_05.jpg"}
                           data-glightbox="description: .custom-desc2_2; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_05.jpg" width={335} layout="fixed" placeholder="none" alt="Christoph Schappeler"/>
                        </a>
                        <div className="glightbox-desc custom-desc2_2">
                            <h2> Christoph Schappeler, um 1551 </h2>
                            <p>Der aus St. Gallen stammende Theologe Christoph Schappeler spielte eine wichtige Rolle
                                für die Reformation in Memmingen. Sebastian Lotzer, zu dem leider keine Abbildung existiert,
                                war ihm eng verbunden.
                                Bildnis vermutlich von Caspar Hagenbuch
                            </p>
                            <p className="copy">Abb. Vadianische Sammlung der Ortsbürgergemeinde St.Gallen, VadSlg PA 1</p>
                        </div>
                        <figcaption>
                            Christoph Schappeler, um 1551 <br/>
                            <span className="description">
                            Bildnis vermutlich von Caspar Hagenbuch
                            </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Memmingen/memmingen_bild_01_09.jpg"}
                           data-glightbox="title: An die Versamlung gemayner Pawerschafft (An die Versammlung gemeiner Bauernschaft), gedruckt in Nürnberg, 1525; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Memmingen/memmingen_bild_01_09.jpg" width={335} layout="fixed" placeholder="none" alt="An die Versamlung gemayner Pawerschafft (An die Versammlung gemeiner Bauernschaft), gedruckt in Nürnberg, 1525"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                In der neueren Forschung geht man davon aus, dass Schappeler die anonym erschienene
                                Flugschrift „An die Versammlung gemeiner Bauernschaft“ verfasst hat. Der Text setzt
                                sich mit dem Verhältnis von Untertanen und Obrigkeiten auseinander. Der Titelholzschnitt
                                zeigt links die Bauern und rechts Adelige und kirchliche Würdenträger.
                            </p>
                            <p className="copy">Abb. Bayerische Staatsbibliothek München, Rar. 1677#Beibd.4</p>
                        </div>
                        <figcaption>
                            An die Versamlung gemayner Pawerschafft (An die Versammlung gemeiner Bauernschaft),
                            gedruckt in Nürnberg, 1525
                            <span className="description">
                                In der neueren Forschung geht man davon aus, dass Schappeler die anonym erschienene
                                Flugschrift „An die Versammlung gemeiner Bauernschaft“ verfasst hat. Der Text setzt
                                sich mit dem Verhältnis von Untertanen und Obrigkeiten auseinander. Der Titelholzschnitt
                                zeigt links die Bauern und rechts Adelige und kirchliche Würdenträger.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>

    )
}

export default TextBlock6
