import * as React from 'react'

const Page = () => {
    return (
        <>
            <h4>Dem christlichen Leser Friede und Gnade Gottes durch Christum.</h4>
            Es gibt viele Widerchristen, die jetzt die versammelte Bauernschaft zum Anlass nehmen, um das Evangelium zu
            schmähen. Sie sagen, das seien die Früchte des neuen Evangeliums, niemandem gehorsam zu sein, sich an allen
            Orten zu erheben und aufzubäumen, sich bewaffnet zu versammeln und zusammenzurotten, geistliche und
            weltliche Obrigkeiten umzustürzen, auszurotten, ja vielleicht gar, sie zu erschlagen.<br/>
            <br/>
            Allen diesen gottlosen, niederträchtigen Urteilern antworten die folgenden Artikel. Erstens, damit sie die
            Schmähung des Wortes Gottes aufheben, zum anderen, um den Ungehorsam, ja die Empörung aller Bauern
            christlich zu entschuldigen.<br/>
            <br/>
            Zum Ersten: Nicht das Evangelium ist die Ursache der Empörung oder des Aufruhrs. Denn es ist die Rede 303
            Christi, des verheißenen Messias, dessen Wort und Leben nichts als Liebe, Friede, Geduld und Einigkeit
            lehren. So, dass alle, die an diesen Christus glauben, liebesfähig, friedlich, geduldig und versöhnlich
            werden. Die Grundlage aller Artikel der Bauern, wie dann klar zu sehen sein wird, besteht darin, das
            Evangelium zu hören und danach zu leben. Wie können dann die Widerchristen das Evangelium eine Ursache der
            Empörung und des Ungehorsams nennen?<br/>
            <br/>
            Dass sich aber einige dieser Widerchristen und Feinde des Evangeliums gegen solch ein Verlangen und Begehren
            auflehnen und aufbäumen, dafür ist nicht das Evangelium die Ursache. Sondern es ist der Teufel, der
            schädlichste Feind des Evangeliums, der das durch den Unglauben in den Seinen erweckt, wodurch das Wort
            Gottes, das Liebe, Friede und Einigkeit lehrt, unterdrückt und den Gläubigen genommen wird.
        </>
    )
}

export default Page;
