import Headerimage from "./Headerimage";
import Novel from "./Novel";
import TextBlock1Blue from "./TextBlock1Blue";
import TextBlock2Beige from "./TextBlock2Beige";
import TextBlock3 from "./TextBlock3";
import TextBlockMore from "./TextBlockMore";
import TextBlock6 from "./TextBlock6";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1Blue,
    Novel,
    TextBlock2Beige,
    TextBlock3,
    TextBlockMore,
    TextBlock6,
    Video,
];

export {Parts};
