import * as F01_10P1 from "./01_10/Page1";

import * as F01_12P1 from "./01_12/Page1";
import * as F01_12P2a from "./01_12/Page2a";
import * as F01_12P2b from "./01_12/Page2b";
import * as F01_12P3a from "./01_12/Page3a";
import * as F01_12P3b from "./01_12/Page3b";
import * as F01_12P4a from "./01_12/Page4a";
import * as F01_12P4b from "./01_12/Page4b";
import * as F01_12P5a from "./01_12/Page5a";
import * as F01_12P5b from "./01_12/Page5b";
import * as F01_12P6a from "./01_12/Page6a";
import * as F01_12P6b from "./01_12/Page6b";
import * as F01_12P7 from "./01_12/Page7";

const Pages = {
    '01_10': {
        1: ['/facsimile/memmingen/01_10_Ratsbescheid1525/01_10_Ratsbescheid1525.jpg', F01_10P1],
    },
    '01_12': {
        1: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel1.jpg', F01_12P1],
        2: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel2a.jpg', F01_12P2a],
        3: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel2b.jpg', F01_12P2b],
        4: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel3a.jpg', F01_12P3a],
        5: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel3b.jpg', F01_12P3b],
        6: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel4a.jpg', F01_12P4a],
        7: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel4b.jpg', F01_12P4b],
        8: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel5a.jpg', F01_12P5a],
        9: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel5b.jpg', F01_12P5b],
        10: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel6a.jpg', F01_12P6a],
        11: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel6b.jpg', F01_12P6b],
        12: ['/facsimile/memmingen/01_12_Zwölf Artikel für zum Blättern/Bauernartikel7.jpg', F01_12P7],
    },
};

export {Pages};
