import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3 = (key) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Sebastian Lotzer – ein überzeugter Laienprediger</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure>
                        <StaticImage src="../../../../static/images/Memmingen/lotzer.jpg" layout="fixed" placeholder="none" alt=""/>
                    </figure>
                </div>
                <div className="col-6">
                    <p>
                        Sebastian Lotzer wurde um 1490 in Horb am Neckar in einer angesehenen Familie geboren. Seine
                        Biografie ist nur lückenhaft überliefert. Er erlernte das Handwerk des Kürschners, ging auf
                        Wanderschaft und kam so nach Memmingen. Hier heiratete er die Kramerstochter Margret Weigelin,
                        deren Geschäft er dann führte.
                    </p>
                    <p>
                        Er setzte sich für die Ideen der Reformation ein und war Christoph Schappeler, dem Prediger der
                        Stadtkirche, eng verbunden. Zwischen 1523 und 1525 verfasste Lotzer mehrere reformatorische
                        Schriften. Er fordert unter anderem zum Kauf des Neuen Testaments auf, es sei die „rechte lebendige
                        Speise für [die] Seelen“. 1525 unterstützte er die Forderungen der Bauern und war als Feldschreiber
                        des Baltringer Haufens maßgeblich an der Abfassung der Zwölf Artikel und der Bundesordnung
                        beteiligt. Nach dem Sieg des Schwäbischen Bundes im Bauernkrieg musste er im April 1525 aus Memmingen
                        fliehen. Sein Sterbeort und -datum sind unbekannt.
                    </p>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3
