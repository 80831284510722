import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock2Beige = (key) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Sebastian Lotzer und Christoph Schappeler in Memmingen</h2>
                    <div className="inner-container">
                        <blockquote>
                            „Wir sollen nach Geboten leben, nicht nach unserem freien fleischlichen Eigensinn, sondern
                            Gott lieben, ihn als unseren Herrn in unserem Nächsten erkennen und alles tun, was auch wir
                            gern hätten und was uns Gott in seinem Abendmahl zuletzt geboten hat. […] Doch nicht allein
                            der Obrigkeit, sondern jedermann gegenüber sollen wir demütig sein.“
                        </blockquote>
                        <span className="author">
                                (Zwölf Artikel)
                            </span>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2Beige
