import * as React from 'react'

const Page = () => {
    return (
        <>
            weisen kann, dass er das Wasser redlich gekauft hat, begehren wir nicht, es ihm mit Gewalt zu nehmen.
            Sondern: Man muss hier ein christliches Einsehen haben, aus brüderlicher Liebe. Aber wer darüber keine
            genügende Auskunft geben kann, soll's gerechterweise einer Gemeinde überlassen.<br/>
            <br/>
            <h4>Der fünfte Artikel</h4>
            Zum Fünften: Wir sind auch beschwert durch den Mangel an Holz. Denn unsere Herrschaften haben alle Wälder
            nur sich allein angeeignet. Wenn dann der arme Mann etwas braucht, muss er's um den doppelten Preis kaufen.
            Unsere Meinung ist deshalb: Was es auch für Wälder seien, es mögen geistliche oder weltliche Herren darüber
            verfügen, wenn diese sie nicht gekauft haben, sollen sie wieder einer ganzen Gemeinde anheim fallen. Und
            eine Gemeinde soll darin frei sein, einem jeden zu erlauben, sein Brennholz nach Bedarf umsonst nach Hause
            zu bringen. Auch wenn Holz zum Zimmern von Nöten sein sollte, soll man es ebenfalls umsonst nehmen, doch mit
            Wissen derer, die von der Gemeinde dazu gewählt wurden. Wenn aber keins vorhanden ist, außer dem, das
            redlich gekauft worden war, dann soll man sich mit den Besitzern brüderlich und christlich einigen. Wenn
            aber der Besitz ursprünglich von ihnen eigenmächtig angeeignet und dann später verkauft worden ist, soll man
            nach Lage der Dinge einen Ausgleich suchen aus dem Vertrauen auf die brüderliche Liebe und das Wort der
            Heiligen Schrift.<br/>
            <br/>
            <h4>Der sechste Artikel</h4>
            Zum Sechsten: Wir sind hart beschwert durch die Dienste, die von Tag zu Tag mehr werden und täglich
            zunehmen. Wir verlangen, dass man dafür ein rechtes Einsehen hat und uns nicht dermaßen beschwert, sondern
            uns gnädig behandelt und dienen lässt, wie unsere Eltern gedient haben, allein nach dem Wort Gottes.
        </>
    )
}

export default Page;
