import * as React from 'react'

const Page = () => {
    return (
        <>
            Zum Zweiten folgt daraus klar und deutlich, dass die Bauern in ihren Artikeln das Evangelium zur Belehrung
            und für ihr Leben begehren, weshalb sie nicht aufrührerisch genannt werden können. Wenn aber Gott die
            Bauern, die ängstlich danach rufen, nach seinem Wort leben zu dürfen, erhören will, wer will den Willen
            Gottes tadeln? Wer will sein Gericht angreifen? Ja, wer will sich seiner Majestät widersetzen? Hat er nicht
            die Kinder Israels erhört und aus der Hand des Pharao befreit , als sie nach ihm riefen? Kann er nicht die
            Seinen auch heute noch erretten? Und das in Kürze. Deshalb, christlicher Leser, lies die nachfolgenden
            Artikel aufmerksam durch und dann urteile.
        </>
    )
}

export default Page;
