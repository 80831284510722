import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>
                Transkription zu:
                Antwort des Rates auf die Eingabe der Memminger Bauern (10 Artikel) vom 15. März 1525
            </h3>

            Hernach folgt ains ersamen rats zu Memmingen antwurt auf seiner vnderthonen, der pawrn, artickel irer
            beschwerungen.<br/>
            <br/>
            Auf den ersten artickel will ain rat seinen vnderthonen auf den pfarren, die im zugehörn, cristenlich
            selsorger, wo sie daran mangel hetten, so erst er die bekomen mag, schaffen vnd verhelfen, so sie auch daran
            benugig sein wöllen. Aber mit Worringen vnd andern pfarren, die aim rat nit zu vergleichen zusteen, will ain
            rat von wegen seiner vnderthonen mit denselben pfarrern, wa bey inen mangel ist, geren ernstlich reden,
            nachmals wa siech ain pfarrer nut daran keren wöllt, dem lechenherren darvmb zuschreiben, seinen vnderthonen
            ainen andern pfarrer zu geben oder seinen vnderthonen zu vergonnen, selbs ainen zu erwelen etc.<br/>
            <br/>
            Auf den andern artickel, den zechenden betreffend, sieht meine herren fur gut an, das die pawrschaft vnd
            vnderthonen also mit dem artickel, den zechenden belangend, still standen, so lang biß die gemainen
            pawrschaft allenthalben bey gemainen stenden des bunds vertragen werden. Waß dann die pawrschaft bey den
            andern iren herren erlangen, wöllen sie iren vnderthonen dasselb auch vnuerzigen nach geben.<br/>
            <br/>
            Auf den dritten artickel, die leibaigenschaft betreffent, wiewol meine herren dieselben also vmb ain
            mercklich somma gelts erkauft haben, vnd die leibaigenschaft ainen cristenman an der sel seligkait nitt
            hindert, noch dann, damit die vnderthonen ains rats genaigten willen sechen vnd erkennen mugen, so wöllend
            sie ire vnderthonen sollicher leibaigenschaft, so fil der aim rat zugehörig vnd verwandt sein, erlaßen vnd
            ledig zelen, doch das sie meinen herren järlich dargegen ain zimlich schirmgelt geben vnd sonst kainen
            andern schirm, dieweil sie in ains rats zwing vnd penn sein, annemen vnd kainen, der nitt frey sey, zu inen
            ziechen laßen, deßgleichen das seine vnderthanen, man- vnd frawenpersonen, zu kainem aigen, sonder freyen
            leuten siech verheyraten vnd sonst ainem rat als ir oberkait in allen zimlichen dingen, als steurn, raisen
            vnd dergleichen, botten vnd verbotten gehorsam seyen, vnd so ainer, des er schuldig ist, zalt, mag er dann
            vnuerhindert ains rats ziechen, wa er will.
        </>
    )
}

export default Page;
